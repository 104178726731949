import React, { useEffect, useState } from "react";
import {
  // Avatar,
  Text,
  Box,
  Grid,
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import Banner from "views/admin/profile/components/Banner";
import banner from "assets/img/auth/banner.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Overview() {
  const [newPassword, setNewPassword] = useState("");
  const [userId, setUserId] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error , setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
      if(!accessToken) {
        history.push('/auth/sign-in');
       
          console.error("Access token not found.");
          return;
      }

    const fetchUserRoleDescription = async () => {
      try {
        const username = localStorage.getItem("username") || null;
        const responseUsers = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor`);

        if (responseUsers.ok) {
          const usersData = await responseUsers.json();
          const user = usersData.find((user) => user.username === username);
          if (!user) {
            console.error("User not found");
            return;
          }
          setUserId(user.doctorId);
        

        } else {
          console.error("Error fetching users");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUserRoleDescription();
  }, []);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      console.log("Passwords do not match.");
      setError("Passwords do not match! Please enter the same password in both fields.");
      return console.error("Passwords doesn't match!");
    }
    try {
      
      const username = localStorage.getItem("username"); 
      console.log("user ID: ",userId);
      const data = {
        username: username,
        doctorId: userId,
        password: newPassword,
        updateUser: username, 
      };
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        console.log("Success");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        console.log("Error updating");
        setError("Error updating password.");
      }
    } catch (error) {
      setError("Error updating password.")
     console.log(`An error occurred while updating the password: ${error.message}`);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.34fr 1fr 1.62fr",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        
        <Banner
          gridArea="1 / 1 / 2 / 2"
          banner={banner}
          name={localStorage.getItem("username") || "User"}
          job="Doctor"
        />
        <Box>
          <FormControl isRequired mt={4}>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Confirm New Password</FormLabel>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </FormControl>
          <Button mt={4} colorScheme="blue" onClick={handlePasswordChange}>
            Update Password
          </Button>
          <Text color="red.400" fontSize="md"  mb="4">{error}</Text>
        </Box>
      </Grid>
    </Box>
  );
}