import React, { useState } from "react";
import {
  ChakraProvider,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react";

const CreateKindForm = () => {
  const [description, setDescription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/Kind`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ description: description }),
        }
      );

      const result = await response.json();
      console.log(result);
      window.location.reload();

      // Reset the form or handle success accordingly
      setDescription("");
    } catch (error) {
      console.error("Error creating kind:", error);
    }
  };

  return (
    <ChakraProvider>
      <Box
        mt={10}
        boxShadow="0px 0px 10px rgba(0, 0, 255, 0.3)"
        p="8"
        borderRadius="md"
        bg="white"
        width={"l"}
      >
        <Heading mb={5}>Create Kind</Heading>
        <form onSubmit={handleSubmit}>
          <FormControl id="description" mb={3}>
            <FormLabel>Description</FormLabel>
            <Input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>

          <Button type="submit" colorScheme="blue">
            Create Kind
          </Button>
        </form>
      </Box>
    </ChakraProvider>
  );
};

export default CreateKindForm;
