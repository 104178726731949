import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  FormLabel,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone } from "react-icons/md";
import Card from "components/card/Card";
import moment from "moment";
import { IoMdEye } from "react-icons/io";

export const ListPatient = () => {
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [sexOptions, setSexOptions] = useState([]);
  const [bloodOptions, setBloodOptions] = useState([]);

  const [error, setError] = useState("");
  const [editablePatient, setEditablePatient] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [editMode, setEditMode] = useState(false); // State to manage edit mode
  const [doctor, setDoctor] = useState("");

  const [updateUser, setUpdateUser] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
const [doc , setDoc]=useState("") ;
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const entryUser = decodedPayload.username;
      const docID = decodedPayload.doctorId
      setUpdateUser(entryUser);
      setDoc(docID);
    } else {
      console.error("Access token not found.");
    }
  }, []);
console.log(doc);
useEffect(() => {
  const fetchPatients = async () => {
    if (!doc) return; // If doc is not set, don't fetch
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/patientBydoc/${doc}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch patients");
      }
      const data = await response.json();
      const updatedPatients = data.map(async (patient) => {
        const sexname = await fetchSexName(patient.sex);
        const bloodname = await fetchSexName(patient.bloodGroup);
        const nameDoctor = await fetchdoctor(patient.doctorId);
        return { ...patient, sexname, nameDoctor, bloodname };
      });
      const resolvedPatients = await Promise.all(updatedPatients);
      setPatients(resolvedPatients);
      setFilteredPatients(resolvedPatients);
    } catch (error) {
      setError(error.message);
    }
  };

  fetchPatients();
}, [doc]);

  const fetchSexName = async (sex) => {
    try {
      const kindResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/kind`
        );
        const kindData = await kindResponse.json();
        const sexKind = kindData.find((kind) => kind.description === sex);
        if (sexKind) {
          const codeResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/code`
          );
          
      if (!codeResponse.ok) {
        throw new Error("Failed to fetch code description");
      }
      const data = await codeResponse.json();
      console.log(data);
      if (Array.isArray(data) && data.length > 0) {
        return data[0].description;
      } else {
        throw new Error("Sex description not found");
      }
    }} catch (error) {
      setError(error.message);
      return "";
    }
  };

  const fetchdoctor = async (doctorId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/doctor/${doctorId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch doctor");
      }
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        return data[0].docName;
      } else {
        throw new Error("doctor  not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };
  useEffect(() => {
    const fetchDoctor = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/doctor`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch doctor");
        }
        const data = await response.json();
        setDoctor(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchDoctor();
  }, []);

  useEffect(() => {
    const fetchSexOptions = async () => {
      try {
        const kindResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/kind`
        );
        const kindData = await kindResponse.json();
        const sexKind = kindData.find((kind) => kind.description === "Sex");
        if (sexKind) {
          const codeResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/code`
          );
          const codes = await codeResponse.json();
          const filteredCodes = codes.filter(
            (code) => code.idCodeKind === sexKind.idCodeKind
          );
          setSexOptions(filteredCodes);
        } else {
          setError('No kind with description "Sex" found');
        }
      } catch (error) {
        setError("Failed to fetch sex options");
      }
    };
    fetchSexOptions();
  }, []);
  useEffect(() => {
    const fetchBloodOptions = async () => {
      try {
        const kindResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/kind`
        );
        const kindData = await kindResponse.json();
        const sexKind = kindData.find((kind) => kind.description === "Blood Group");
        if (sexKind) {
          const codeResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/code`
          );
          const codes = await codeResponse.json();
          const filteredCodes = codes.filter(
            (code) => code.idCodeKind === sexKind.idCodeKind
          );
          setBloodOptions(filteredCodes);
        } else {
          setError("No kind with description Blood found");
        }
      } catch (error) {
        setError("Failed to fetch blood options");
      }
    };
    fetchBloodOptions();
  }, []);
  const handleDeletePatient = async (patientId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/patient`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ patientId }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete patient");
      }
      setPatients(
        patients.filter((patient) => patient.patientId !== patientId)
      );
      setFilteredPatients(
        filteredPatients.filter((patient) => patient.patientId !== patientId)
      );
    } catch (error) {
      setError("Failed to delete patient: " + error.message);
    }
  };

  const handleEditPatient = (patient) => {
    setEditablePatient(patient);
  };

  const handleSavePatient = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/patient`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            patientId: editablePatient.patientId,
            fname: editablePatient.fname,
            mname: editablePatient.mname,
            lname: editablePatient.lname,
            doctorId: editablePatient.doctorId,
            dob: editablePatient.dob,
            sex: editablePatient.sex,
            bloodGroup: editablePatient.bloodGroup,
            materialStatus: editablePatient.materialStatus,
            job: editablePatient.job,
            jobDescription: editablePatient.jobDescription,
            smoking: editablePatient.smoking,
            phone: editablePatient.phone,
            notes: editablePatient.notes,
            updateUser: updateUser,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Failed to update patient: ${JSON.stringify(errorData)}`
        );
      }

      setPatients(
        patients.map((patient) => {
          if (patient.patientId === editablePatient.patientId) {
            return editablePatient;
          }
          return patient;
        })
      );
      setFilteredPatients(
        filteredPatients.map((patient) => {
          if (patient.patientId === editablePatient.patientId) {
            return editablePatient;
          }
          return patient;
        })
      );

      setEditablePatient(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = patients.filter(
      (patient) =>
        patient.fname.toLowerCase().includes(query) ||
        patient.lname.toLowerCase().includes(query)
    );
    setFilteredPatients(filtered);
  };

  const handleViewPatient = (patient) => {
    setSelectedPatient(patient);
    setEditMode(false);
  };

  const handleCloseModal = () => {
    setSelectedPatient(null);
    setEditMode(false);
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color="gray.800"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Patient List
        </Text>
        <Input
          placeholder="Search patient..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>
      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr bg="blue.500" textColor="white">
              <Th textColor="white">ID</Th>
              <Th textColor="white">First Name</Th>
              <Th textColor="white">Middle Name</Th>
              <Th textColor="white">Last Name</Th>
              <Th textColor="white">DOB</Th>
              <Th textColor="white">Sex</Th>
              <Th textColor="white">Blood Group</Th>
              <Th textColor="white">Phone</Th>
              <Th textColor="white">Entry User</Th>
              <Th textColor="white">Entry Date</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredPatients.map((patient, index) => (
              <Tr key={patient.patientId}>
                <Td>{index + 1}</Td>
                <Td>{patient.fname}</Td>
                <Td>{patient.mname}</Td>
                <Td>{patient.lname}</Td>
                <Td>{moment(patient.dob).format("YYYY-MM-DD")}</Td>
                <Td>{patient.sex}</Td>
                <Td>{patient.bloodGroup}</Td>
                <Td>{patient.phone}</Td>
                <Td>{patient.entryUser}</Td>
                <Td>{moment(patient.entryDate).format("YYYY-MM-DD")}</Td>
                <Td>
                  <Button
                    leftIcon={<MdEdit />}
                    colorScheme="blue"
                    size="sm"
                    onClick={() => handleViewPatient(patient)}
                  >
                    Edit
                  </Button>
                  <Button
                    leftIcon={<MdDelete />}
                    colorScheme="red"
                    size="sm"
                    ml={2}
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to delete this patient?"
                        )
                      ) {
                        handleDeletePatient(patient.patientId);
                      }
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    leftIcon={<IoMdEye />}
                    colorScheme="teal"
                    size="sm"
                    ml={2}
                    onClick={() => handleViewPatient(patient)}
                  >
                    View
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {selectedPatient && (
        <Modal isOpen={true} onClose={handleCloseModal} size="xl">
          <ModalOverlay />
          <ModalContent
            display="flex"
            alignItems="center"
            justifyContent="center"
            animation="fadeIn 0.3s ease-out"
          >
            <ModalHeader>View Patient</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box text>
                <Flex direction="column" mb="20px">
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={9}>
                        First Name:
                      </Text>
                      <Flex>
                        {editablePatient &&
                        editablePatient.patientId ===
                          selectedPatient.patientId ? (
                          <Input
                            value={editablePatient.fname}
                            onChange={(e) =>
                              setEditablePatient({
                                ...editablePatient,
                                fname: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedPatient.fname}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={5}>
                        Middle Name:
                      </Text>
                      <Flex>
                        {editablePatient &&
                        editablePatient.patientId ===
                          selectedPatient.patientId ? (
                          <Input
                            value={editablePatient.mname}
                            onChange={(e) =>
                              setEditablePatient({
                                ...editablePatient,
                                mname: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedPatient.mname}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={9}>
                        Last Name:
                      </Text>
                      <Flex>
                        {editablePatient &&
                        editablePatient.patientId ===
                          selectedPatient.patientId ? (
                          <Input
                            value={editablePatient.lname}
                            onChange={(e) =>
                              setEditablePatient({
                                ...editablePatient,
                                lname: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedPatient.lname}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="sm" color="black" mr={5}>
                        Phone Number:
                      </Text>
                      <Flex>
                        {editablePatient &&
                        editablePatient.patientId ===
                          selectedPatient.patientId ? (
                          <Input
                            value={editablePatient.phone}
                            onChange={(e) =>
                              setEditablePatient({
                                ...editablePatient,
                                phone: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedPatient.phone}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={7}>
                        Description:
                      </Text>
                      <Flex>
                        {editablePatient &&
                        editablePatient.patientId ===
                          selectedPatient.patientId ? (
                          <Input
                            value={editablePatient.notes}
                            onChange={(e) =>
                              setEditablePatient({
                                ...editablePatient,
                                notes: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedPatient.notes}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={20}>
                        Sex:
                      </Text>
                      <Flex>
                        {editablePatient &&
                        editablePatient.patientId ===
                          selectedPatient.patientId ? (
                          <Select
                            placeholder="Select"
                            id="sex"
                            name="sex"
                            value={editablePatient.sex}
                            onChange={(e) =>
                              setEditablePatient({
                                ...editablePatient,
                                sex: e.target.value,
                              })
                            }
                          >
                            {sexOptions.map((option) => (
                              <option key={option.idCode} value={option.idCode}>
                                {option.description}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          <Text ml={1}>{selectedPatient.sex}</Text>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={5}>
                        Date of Birth:
                      </Text>
                      <Flex>
                        {editablePatient &&
                        editablePatient.patientId ===
                          selectedPatient.patientId ? (
                          <Input
                            type="date"
                            value={editablePatient.dob}
                            onChange={(e) =>
                              setEditablePatient({
                                ...editablePatient,
                                dob: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>
                            {moment(selectedPatient.dob).format("YYYY-MM-DD")}
                          </>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={5}>
                        Blood Group:
                      </Text>
                      <Flex>
                        {editablePatient &&
                        editablePatient.patientId ===
                          selectedPatient.patientId ? (
                          <Select
                            placeholder="Select"
                            id="sex"
                            name="sex"
                            value={editablePatient.bloodGroup}
                            onChange={(e) =>
                              setEditablePatient({
                                ...editablePatient,
                                bloodGroup: e.target.value,
                              })
                            }
                          >
                            {bloodOptions.map((option) => (
                              <option key={option.idCode} value={option.idCode}>
                                {option.description}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          <>{selectedPatient.bloodGroup}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={1}>
                        Material Status:
                      </Text>
                      <Flex>
                        {editablePatient &&
                        editablePatient.patientId ===
                          selectedPatient.patientId ? (
                          <Input
                            value={editablePatient.materialStatus}
                            onChange={(e) =>
                              setEditablePatient({
                                ...editablePatient,
                                materialStatus: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedPatient.materialStatus}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={20}>
                        Job:
                      </Text>
                      <Flex>
                        {editablePatient &&
                        editablePatient.patientId ===
                          selectedPatient.patientId ? (
                          <Checkbox
                            isChecked={editablePatient.job ? 1 : 0}
                            onChange={(e) =>
                              setEditablePatient({
                                ...editablePatient,
                                job: e.target.checked ? 1 : 0,
                              })
                            }
                          >
                            Job?
                          </Checkbox>
                        ) : (
                          <Text ml={1}>
                            {selectedPatient.job === 1
                              ? "Employed"
                              : "Unemployed"}
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={1}>
                        Job Description:
                      </Text>
                      <Flex>
                        {editablePatient &&
                        editablePatient.patientId ===
                          selectedPatient.patientId ? (
                          <Input
                            value={editablePatient.jobDescription}
                            onChange={(e) =>
                              setEditablePatient({
                                ...editablePatient,
                                jobDescription: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedPatient.jobDescription}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={14}>
                        Smoking:
                      </Text>
                      <Flex>
                        {editablePatient &&
                        editablePatient.patientId ===
                          selectedPatient.patientId ? (
                          <Checkbox
                            isChecked={editablePatient.smoking ? 1 : 0}
                            onChange={(e) =>
                              setEditablePatient({
                                ...editablePatient,
                                smoking: e.target.checked ? 1 : 0 ,
                              })
                            }
                          >
                            Smoker?
                          </Checkbox>
                        ) : (
                          <>{selectedPatient.smoking === 1 ? "Yes" : "No"}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>

                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={16}>
                        Doctor:
                      </Text>
                      <Flex>
                        {editablePatient &&
                        editablePatient.patientId ===
                          selectedPatient.patientId ? (
                          <Select
                            value={editablePatient.doctorId}
                            mb={4}
                            onChange={(e) =>
                              setEditablePatient({
                                ...editablePatient,
                                doctorId: e.target.value,
                              })
                            }
                          >
                            {doctor.map((doctors) => (
                              <option
                                key={doctors.doctorId}
                                value={doctors.doctorId}
                              >
                                {doctors.docName}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          <>{selectedPatient.nameDoctor}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                </Flex>
                {editablePatient &&
                editablePatient.patientId === selectedPatient.patientId ? (
                  <>
                    <Button
                      leftIcon={<MdDone />}
                      colorScheme="green"
                      size="sm"
                      onClick={handleSavePatient}
                    >
                      Save
                    </Button>
                    <Button
                      leftIcon={<MdDelete />}
                      colorScheme="red"
                      ml={2}
                      size="sm"
                      onClick={() => setEditablePatient(null)}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      leftIcon={<MdEdit />}
                      colorScheme="blue"
                      size="sm"
                      onClick={() => handleEditPatient(selectedPatient)}
                    >
                      Edit
                    </Button>
                    <Button
                      leftIcon={<MdDelete />}
                      colorScheme="red"
                      size="sm"
                      ml={2}
                      onClick={handleCloseModal}
                    >
                      Close
                    </Button>
                  </>
                )}
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" onClick={handleCloseModal}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Card>
  );
};

export default ListPatient;
