import React, { useState } from "react";
import { Button, Container, Center } from "@chakra-ui/react";
import { MdAdd, MdRemove } from "react-icons/md";
import CreateSchool from "./components/createSchool";
import  ListSchools  from "./components/schoolList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";


export default function School() {
    const history = useHistory();

	useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
          if(!accessToken) {
            history.push('/auth/sign-in');
           
              console.error("Access token not found.");
              return;
          }
      }, []);
    return (
        <Container maxW="xxl" mt={100}>
            <div style={{marginBottom:  '20px'}}>
                {<CreateSchool />}
            </div>
            <ListSchools />
        </Container>
    );
}
