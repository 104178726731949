import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { RiUserSettingsLine } from "react-icons/ri";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue, Collapse, Icon } from "@chakra-ui/react";
import { MdSettings, MdArrowDropDown, MdArrowDropUp, MdLock } from "react-icons/md";

export function SidebarLinks(props) {
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");
  let activeIcon = useColorModeValue("blue.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("blue.500", "blue.400");

  const { routes } = props;
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const renderDropdownLinks = () => {
    const dropdownLinks = routes.filter(route =>
      route.name === "Role" ||
      route.name === "Role Permission" ||
      route.name === "Permission" ||
      route.name === "Sign In"
    );

    return dropdownLinks.map((link, index) => {
      const isDisabled = link.name === "Permission" || link.name === "Role" || link.name === "Role Permission";
      
      return (
        <NavLink key={index} to={isDisabled ? "#" : link.layout + link.path}>
          <HStack spacing={activeRoute(link.path.toLowerCase()) ? "22px" : "26px"} py='5px' ps='10px' opacity={isDisabled ? 0.5 : 1} pointerEvents={isDisabled ? "none" : "auto"}>
            <Flex w='100%' alignItems='center' justifyContent='center'>
              <Box color={activeRoute(link.path.toLowerCase()) ? activeIcon : textColor} me='18px'>
                {link.icon}
              </Box>
              <Text me='auto' color={activeRoute(link.path.toLowerCase()) ? activeColor : textColor} fontWeight={activeRoute(link.path.toLowerCase()) ? "bold" : "normal"}>
                {link.name}
              </Text>
            </Flex>
            <Box h='36px' w='4px' bg={activeRoute(link.path.toLowerCase()) ? brandColor : "transparent"} borderRadius='5px' />
          </HStack>
        </NavLink>
      );
    });
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.layout === "/admin" && route.name !== "Role" && route.name !== "Role Permission" && route.name !== "Permission" && route.name !== "Sign In") {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            <HStack spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"} py='5px' ps='10px'>
              <Flex w='100%' alignItems='center' justifyContent='center'>
                <Box color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor} me='18px'>
                  {route.icon}
                </Box>
                <Text me='auto' color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor} fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}>
                  {route.name}
                </Text>
              </Flex>
              <Box h='36px' w='4px' bg={activeRoute(route.path.toLowerCase()) ? brandColor : "transparent"} borderRadius='5px' />
            </HStack>
          </NavLink>
        );
      }
      return null;
    });
  };

  return (
    <>
      {createLinks(routes)}

      <Box cursor="pointer" onClick={toggleDropdown}>
        <HStack spacing="26px" py='5px' ps='10px'>
          <Flex w='100%' alignItems='center' justifyContent='center'>
            <Box color={textColor} me='18px'>
              <Icon as={MdSettings} boxSize={5} />
            </Box>
            <Text me='auto' color={textColor} fontWeight="normal">
              Settings
            </Text>
            <Box color={isDropdownOpen ? activeIcon : textColor} me='0px'>
              <Icon as={isDropdownOpen ? MdArrowDropUp : MdArrowDropDown} boxSize={8} pt='1' />
            </Box>
          </Flex>
        </HStack>
      </Box>

      <Collapse in={isDropdownOpen}>
        <Box ml="30px">
          {renderDropdownLinks()}
        </Box>
      </Collapse>
    </>
  );
}

export default SidebarLinks;
