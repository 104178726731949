import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RtlLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { SessionProvider } from "context/SessionContext";
import PrivateRoute from "components/PrivateRoute";
import Doctor from "views/admin/default/index";
import SessionPage from "views/admin/default/index";
import SessionDetailsPage from "views/admin/default/SessionDetailsPage";
import SignIn from './views/auth/signIn';
import NotFound from 'views/NotFound';
const accessToken = localStorage.getItem("accessToken");
const initialRoute = accessToken ? "/admin" : "/auth";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <SessionProvider>
          <HashRouter>
            <Switch>
              <Route path={`/auth`} component={AuthLayout} />
              <Route path={`/admin`} component={AdminLayout} />
             { /*<Route path={`/rtl`} component={RtlLayout} />*/}
              <PrivateRoute
                path="/admin/session-details"
                component={SessionDetailsPage}
              />
              <Route path="/admin/session" component={SessionPage} />
              <Route path="/doctor" component={Doctor} />
              <Route path="/signin" element={<SignIn />} />
              <Redirect exact from="/" to={initialRoute} /> 
              <Route path="*" component={NotFound} /> {/* Catch-all route for 404 */}
              {/* Redirect only when the route is exact */}
            </Switch>
          </HashRouter>
        </SessionProvider>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
