import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdSettings,
  MdPermContactCalendar,
  MdWorkspacePremium,
  MdHomeRepairService,
  MdOutlineNaturePeople,
  MdCategory,
  MdDisabledByDefault,
  MdImage,
  MdMyLocation,
  MdSchool,
  MdEventNote,
  MdPeopleOutline,
  MdStyle,
  MdHealing
} from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
// import users from "views/admin/Users";
import Role from "views/admin/Role";
import RolePermission from "views/admin/RolePermission";


import Code from "views/admin/Code";
import Defaults from "views/Defaults/Defaults.jsx";

import School from "views/School/index";
// Auth Imports
import SignInCentered from "views/auth/signIn";

// Permission import
import Permission from "views/admin/Permission/Permission.jsx";



import SessionPage from "views/admin/default/index"; // Correct import path
import SessionDetailsPage from "views/admin/default/SessionDetailsPage"; // Correct import path
import Doctor from "views/Doctors/Doctor";

import Prompt from "views/Prompts/prompts.jsx";
import Style from "views/Style/style.jsx";
import Patient from "views/Patient/patient.jsx";
import { FaBeer, FaCommentDots, FaUserMd } from "react-icons/fa";
const routes = [
  {
    name: "Session",
    layout: "/admin",
    path: "/session",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "School",
    layout: "/admin",
    path: "/school",
    icon: <Icon as={MdSchool} width="20px" height="20px" color="inherit" />,
    component: School,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
  },
 
  // {
  //   name: "Users",
  //   layout: "/admin",
  //   path: "/users",
  //   icon: (
  //     <Icon as={MdPermContactCalendar} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: users,
  // },
  {
    name: "Role",
    layout: "/admin",
    path: "/role",
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    component: Role,
  },
  {
    name: "Role Permission",
    layout: "/admin",
    path: "/permrole",
    icon: (
      <Icon as={RiUserSettingsLine} width="20px" height="20px" color="inherit" />
    ),
    component: RolePermission,
  },
  {
    name: "Permission",
    layout: "/admin",
    path: "/permission",
    icon: (
      <Icon as={MdWorkspacePremium} width="20px" height="20px" color="inherit" />
    ),
    component: Permission,
  },
  {
    name: "Patient",
    layout: "/admin",
    path: "/patient",
    icon: (
      <Icon as={MdHealing} width="20px" height="20px" color="inherit" />
    ),
    component: Patient,
  },
  {
    name: "Code",
    layout: "/admin",
    path: "/code",
    icon: (
      <Icon as={MdHomeRepairService} width="20px" height="20px" color="inherit" />
    ),
    component: Code,
  },
  // {
  //   name: "Defaults",
  //   layout: "/admin",
  //   path: "/defaults",
  //   icon: (
  //     <Icon as={MdDisabledByDefault} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: Defaults,
  // },

 {
    name: "Doctors",
    layout: "/admin",
    path: "/doctor",
    icon: <Icon as={FaUserMd} width="20px" height="20px" color="inherit" />,
    component: Doctor
  },
  {
    name: "Session Details",
    layout: "/admin",
    path: "/session-details",
    icon: <Icon as={MdEventNote} width="20px" height="20px" color="inherit" />,
    component: SessionDetailsPage,
  },
  {
    name: "Prompts",
    layout: "/admin",
    path: "/prompts",
    icon: <Icon as={FaCommentDots} width="20px" height="20px" color="inherit" />,
    component: Prompt
  },
  {
    name: "Style",
    layout: "/admin",
    path: "/style",
    icon: <Icon as={MdStyle} width="20px" height="20px" color="inherit" />,
    component: Style
  },
  {
    
    //name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    //icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
];

export default routes;
