import React, { useState, useEffect } from 'react';
import {
  Box, Flex, Text, Textarea, Button, Select, useColorMode, useToast, Tag, TagLabel, TagCloseButton, VStack, HStack, Switch
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useSession } from '../../../context/SessionContext';

const SessionPage = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [entryUser, setEntryUser] = useState('');
  const [updateUser, setUpdateUser] = useState('');
  const [selectedPatient, setSelectedPatient] = useState('');
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [patients, setPatients] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [schools, setSchools] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState('');
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [styles, setStyles] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [promptContent, setPromptContent] = useState('');
  const toast = useToast();
  const history = useHistory();
  const { startSession } = useSession();
  const [pname, setPName] = useState();
  const [SchName, setSchName] = useState();
  const [styName, setStyName] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disabled state

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
        const [header, payload, signature] = accessToken.split('.');
        const decodedPayload = JSON.parse(atob(payload));
        const username = decodedPayload.username;
        setEntryUser(username);
        setUpdateUser(username);
    } else {
      history.push('/auth/sign-in');
     
        console.error("Access token not found.");
        return;
    }

    const fetchPatients = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/patient`);
        if (!response.ok) {
          throw new Error('Failed to fetch patients');
        }
        const data = await response.json();
        setPatients(data);
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to load patients.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    };

    const fetchSchools = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/school`);
        if (!response.ok) {
          throw new Error('Failed to fetch schools');
        }
        const data = await response.json();
        setSchools(data);
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to load schools.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    };

    const fetchStyles = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/style`);
        if (!response.ok) {
          throw new Error('Failed to fetch styles');
        }
        const data = await response.json();
        setStyles(data);
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to load styles.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    };

    const fetchPrompts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/prompt`);
        if (!response.ok) {
          throw new Error('Failed to fetch prompts');
        }
        const data = await response.json();
        setPrompts(data);
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to load prompts.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    };

    fetchPatients();
    fetchSchools();
    fetchStyles();
    fetchPrompts();
  }, [toast]);

  // Update prompt content based on selected styles and schools
  const updatePromptContent = (prompt, styles, schools) => {
    const stylesText = styles.length > 0 ? `using these styles of psychology: ${styles.join(', ')}` : '';
    const schoolsText = schools.length > 0 ? `following these schools in psychology: ${schools.join(', ')}` : '';
    return `${prompt}\n${stylesText}\n${schoolsText}`;
  };

  // Handle adding a selected school to the list
  const handleAddSchool = () => {
    if (selectedSchool && !selectedSchools.includes(selectedSchool)) {
      const updatedSchools = [...selectedSchools, selectedSchool];
      setSelectedSchools(updatedSchools);
      const updatedSchoolsNames = updatedSchools.map(id => schools.find(school => school.schoolId === id)?.name);
      const updatedStylesNames = selectedStyles.map(id => styles.find(style => style.styleId === id)?.name);
      setPromptContent(updatePromptContent(promptContent, updatedStylesNames, updatedSchoolsNames));
    }
  };

  // Handle adding a selected style to the list
  const handleAddStyle = () => {
    if (selectedStyle && !selectedStyles.includes(selectedStyle)) {
      const updatedStyles = [...selectedStyles, selectedStyle];
      setSelectedStyles(updatedStyles);
      const updatedStylesNames = updatedStyles.map(id => styles.find(style => style.styleId === id)?.name);
      const updatedSchoolsNames = selectedSchools.map(id => schools.find(school => school.schoolId === id)?.name);
      setPromptContent(updatePromptContent(promptContent, updatedStylesNames, updatedSchoolsNames));
    }
  };

  // Handle starting the session
  const handleStartSession = async () => {
    const sessionData = {
      patientId: selectedPatient,
      schoolId: selectedSchools[0],
      styleId: selectedStyles[0],
      promptId: selectedPrompt,
      doctorId: entryUser,
      entryUser: entryUser,
      updateUser: updateUser,
    };

    const sessionDataV = {
      selectedPatient,
      selectedSchools,
      selectedStyles,
      selectedPrompt,
      promptContent,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(sessionData)
      });

      if (!response.ok) {
        throw new Error('Failed to create session');
      }

      const data = await response.json();
      console.log('Session created:', data);

      // Start session with the user's prompt content
      await fetch(`${process.env.REACT_APP_API_URL}/api/startSession`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ promptContent })
      });

      startSession(sessionDataV);
      history.push('/admin/session-details');
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to start session.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  // Handle change in selected prompt
  const handlePromptChange = (e) => {
    const selectedPromptId = e.target.value;
    setSelectedPrompt(selectedPromptId);
    const prompt = prompts.find((p) => p.promptId === selectedPromptId);
    if (prompt) {
      const updatedStylesNames = selectedStyles.map(id => styles.find(style => style.styleId === id)?.name);
      const updatedSchoolsNames = selectedSchools.map(id => schools.find(school => school.schoolId === id)?.name);
      setPromptContent(updatePromptContent(prompt.content, updatedStylesNames, updatedSchoolsNames));
    } else {
      setPromptContent('No Prompt Selected');
    }
  };

  // Handle change in prompt content
  const handlePromptContentChange = (e) => {
    setPromptContent(e.target.value);
  };

  // Handle change in selected patient
  const handlePatientChange = (e) => {
    setSelectedPatient(e.target.value);
  };

  // Validation check for all required fields
  useEffect(() => {
    if (selectedPatient && selectedSchools.length > 0 && selectedStyles.length > 0 && selectedPrompt) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [selectedPatient, selectedSchools, selectedStyles, selectedPrompt]);

  return (
    <Box px={{ base: "4", md: "6", lg: "8" }} py="5">
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="2xl" fontWeight="bold">Session</Text>
        <Button onClick={toggleColorMode}>{colorMode === 'light' ? 'Dark' : 'Light'} Mode</Button>
      </Flex>

      <VStack spacing="4" mt="4" alignItems="flex-start" width="100%">
        <Flex>
          <Text padding={2}>Patient</Text>
          <HStack spacing="4" width="100%">
            <Select
              placeholder="Select Patient"
              value={selectedPatient}
              onChange={handlePatientChange}
              width="200px"
            >
              {patients.map((patient) => (
                <option key={patient.patientId} value={patient.patientId}>
                  {patient.fname} {patient.lname}
                </option>
              ))}
            </Select>
            {selectedPatient && (
              <Tag size="lg" colorScheme="blue">
                <TagLabel>{patients.find(patient => patient.patientId === selectedPatient)?.fname} {patients.find(patient => patient.patientId === selectedPatient)?.lname}</TagLabel>
                <TagCloseButton onClick={() => setSelectedPatient('')} />
              </Tag>
            )}
          </HStack>
        </Flex>

        <Flex>
          <Text padding={2}>School</Text>
          <HStack spacing="4" width="100%">
            <Select
              placeholder="Select School"
              value={selectedSchool}
              onChange={(e) => setSelectedSchool(e.target.value)}
              width="200px"
            >
              {schools.map((school) => (
                <option key={school.schoolId} value={school.schoolId}>
                  {school.name}
                </option>
              ))}
            </Select>
            <Button onClick={handleAddSchool}>Add School</Button>
          </HStack>
          <HStack spacing="2" mt="2">
            {selectedSchools.map((schoolId) => (
              <Tag size="lg" key={schoolId} colorScheme="green">
                <TagLabel>{schools.find(school => school.schoolId === schoolId)?.name}</TagLabel>
                <TagCloseButton onClick={() => setSelectedSchools(selectedSchools.filter((s) => s !== schoolId))} />
              </Tag>
            ))}
          </HStack>
        </Flex>

        <Flex>
          <Text padding={2}>Style</Text>
          <HStack spacing="4" width="100%">
            <Select
              placeholder="Select Style"
              value={selectedStyle}
              onChange={(e) => setSelectedStyle(e.target.value)}
              width="200px"
            >
              {styles.map((style) => (
                <option key={style.styleId} value={style.styleId}>
                  {style.name}
                </option>
              ))}
            </Select>
            <Button onClick={handleAddStyle}>Add Style</Button>
          </HStack>
          <HStack spacing="2" mt="2">
            {selectedStyles.map((styleId) => (
              <Tag size="lg" key={styleId} colorScheme="purple">
                <TagLabel>{styles.find(style => style.styleId === styleId)?.name}</TagLabel>
                <TagCloseButton onClick={() => setSelectedStyles(selectedStyles.filter((s) => s !== styleId))} />
              </Tag>
            ))}
          </HStack>
        </Flex>

        <Flex>
          <Text padding={2}>Prompt</Text>
          <HStack spacing="4" width="100%">
            <Select
              placeholder='Select Prompt'
              value={selectedPrompt}
              onChange={handlePromptChange}
              width="200px"
            >
              {prompts.map((prompt) => (
                <option key={prompt.promptId} value={prompt.promptId}>
                  {prompt.description}
                </option>
              ))}
            </Select>
          </HStack>
        </Flex>
        {selectedPrompt && (
          <Textarea
            mt="4"
            value={promptContent}
            onChange={handlePromptContentChange}
          />
        )}

        <Flex mt="4" alignItems="center">
          <Button colorScheme="blue" onClick={handleStartSession} disabled={isButtonDisabled}>Start Session</Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default SessionPage;
