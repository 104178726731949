import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone, MdCancel } from "react-icons/md";
import Card from "components/card/Card";
import moment from "moment";

export const ListSchools = () => {
  const [schools, setSchools] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [error, setError] = useState("");
  const [editableSchool, setEditableSchool] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/school`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch schools");
        }
        const data = await response.json();
        setSchools(data);
        setFilteredSchools(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchSchools();
  }, []);

  const handleDeleteSchool = async (schoolId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/school`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ schoolId }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete school");
      }
      setSchools(schools.filter((school) => school.schoolId !== schoolId));
      setFilteredSchools(
        filteredSchools.filter((school) => school.schoolId !== schoolId)
      );
    } catch (error) {
      console.error("Error deleting school:", error);
      setError("Failed to delete school");
    }
  };

  const handleEditSchool = (school) => {
    setEditableSchool(school);
  };

  const handleSaveSchool = async () => {
    try {
      const { id } = editableSchool;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/school/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editableSchool),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update school");
      }

      setSchools((prevSchools) => {
        const updatedSchoolIndex = prevSchools.findIndex(
          (school) => school.id === id
        );
        const newSchools = [...prevSchools];
        newSchools[updatedSchoolIndex] = editableSchool;
        return newSchools;
      });

      setFilteredSchools((prevFilteredSchools) => {
        const updatedSchoolIndex = prevFilteredSchools.findIndex(
          (school) => school.id === id
        );
        const newFilteredSchools = [...prevFilteredSchools];
        newFilteredSchools[updatedSchoolIndex] = editableSchool;
        return newFilteredSchools;
      });

      setEditableSchool(null);
    } catch (error) {
      console.error("Error updating school:", error);
      setError("Failed to update school");
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = schools.filter((school) =>
      school.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredSchools(filtered);
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color="gray.800"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          School List
        </Text>
        <Input
          placeholder="Search school..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>
      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr bg="gray.200">
              <Th textAlign="center">Id</Th>
              <Th textAlign="center">Name</Th>
              <Th textAlign="center">Description</Th>
              <Th textAlign="center">Code</Th>
              <Th textAlign="center">Entry User</Th>
              <Th textAlign="center">Entry Date</Th>
              <Th textAlign="center">Update User</Th>
              <Th textAlign="center">Update Date</Th>
              <Th textAlign="center">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredSchools.map((school, index) => (
              <Tr key={school.id}>
                <Td textAlign="center">{index + 1}</Td>
                <Td textAlign="center">
                  {editableSchool && editableSchool.id === school.id ? (
                    <Input
                      type="text"
                      value={editableSchool.name}
                      onChange={(e) =>
                        setEditableSchool({
                          ...editableSchool,
                          name: e.target.value,
                        })
                      }
                    />
                  ) : (
                    school.name
                  )}
                </Td>
                <Td textAlign="center">
                  {editableSchool && editableSchool.id === school.id ? (
                    <Input
                      type="text"
                      value={editableSchool.description}
                      onChange={(e) =>
                        setEditableSchool({
                          ...editableSchool,
                          description: e.target.value,
                        })
                      }
                    />
                  ) : (
                    school.description
                  )}
                </Td>
                <Td textAlign="center">
                  {editableSchool && editableSchool.id === school.id ? (
                    <Input
                      type="text"
                      value={editableSchool.code}
                      onChange={(e) =>
                        setEditableSchool({
                          ...editableSchool,
                          code: e.target.value,
                        })
                      }
                    />
                  ) : (
                    school.code
                  )}
                </Td>
                <Td textAlign="center">{school.entryUser}</Td>
                <Td textAlign="center">
                  {moment(school.entryDate).format("YYYY-MM-DD")}
                </Td>
                <Td textAlign="center">{school.updateUser}</Td>
                <Td textAlign="center">
                  {moment(school.updateDate).format("YYYY-MM-DD")}
                </Td>
                <Td textAlign="center">
                  {editableSchool && editableSchool.id === school.id ? (
                    <Flex justifyContent="center">
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSaveSchool}
                        mr={2}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdCancel />}
                        colorScheme="red"
                        size="sm"
                        onClick={() => setEditableSchool(null)}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  ) : (
                    <Flex justifyContent="center">
                      <Button
                        leftIcon={<MdEdit />}
                        colorScheme="blue"
                        size="sm"
                        onClick={() => handleEditSchool(school)}
                        mr={2}
                      >
                        Edit
                      </Button>
                      <Button
                        leftIcon={<MdDelete />}
                        colorScheme="red"
                        size="sm"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this school?"
                            )
                          ) {
                            handleDeleteSchool(school.schoolId);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </Flex>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      {error && (
        <Text color="red.500" mt="4">
          {error}
        </Text>
      )}
    </Card>
  );
};

export default ListSchools;
