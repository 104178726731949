import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Select,
  Flex,
  FormControl,
  FormLabel,
  Checkbox,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { MdOutlineKingBed } from "react-icons/md";

const CreatePatientForm = () => {
  const [patientId, setPatientId] = useState("");
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [lname, setLname] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [dob, setDob] = useState("");
  const [sex, setSex] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [materialStatus , setMaterialStatus] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [job, setJob] = useState(false);
  const [smoking, setSmoking] = useState(false);
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  const [entryDate, setEntryDate] = useState("");
  const [entryUser, setEntryUser] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [updateUser, setUpdateUser] = useState("");
  const [doctorIdOptions, setDoctorIdOptions] = useState([]);
  const [sexOptions, setSexOptions] = useState([]);
  const [bloodOptions, setBloodOptions] = useState([]);
  const [statusOptions , setStatusOptions] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const username = decodedPayload.username;
      setEntryUser(username);
      setUpdateUser(username);
    } else {
      console.error("Access token not found.");
    }
  }, []);
  useEffect(() => {
    const fetchSex = async () => {
      try {
        const kindResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/kind`
        );
        const kindData = await kindResponse.json();
        const sexKind = kindData.find((kind) => kind.description === "Sex");
        if (sexKind) {
          const codeResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/code`
          );
          const codes = await codeResponse.json();
          const filteredCodes = codes.filter(
            (code) => code.idCodeKind === sexKind.idCodeKind
          );
          console.log(filteredCodes);
          setSexOptions(filteredCodes);
        } else {
          setError('No kind with description "sex" found');
        }
      } catch (error) {
        setError("Failed to fetch sex");
      }
    };
    fetchSex();
    const fetchBlood = async () => {
      try {
        const kindResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/kind`
        );
        const kindData = await kindResponse.json();
        const sexKind = kindData.find((kind) => kind.description === "Blood Group");
        if (sexKind) {
          const codeResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/code`
          );
          const codes = await codeResponse.json();
          const filteredCodes = codes.filter(
            (code) => code.idCodeKind === sexKind.idCodeKind
          );
          console.log(filteredCodes);
          setBloodOptions(filteredCodes);
        } else {
          setError("No kind with description blood found");
        }
      } catch (error) {
        setError("Failed to fetch blood");
      }
    };
    fetchBlood();
    const fetchMaterialStatus = async () => {
      try{
        const kindResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/kind`
        );
        const kindData = await kindResponse.json();
        const statusKind = kindData.find((kind) => kind.description == "Material Status");
        if(statusKind){
          const codeResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/code`
          );
        
        const codes = await codeResponse.json();
        const filteredCodes = codes.filter(
          (code) => code.idCodeKind == statusKind.idCodeKind
        );
        console.log(filteredCodes);
        setStatusOptions(filteredCodes);
        }else{
          setError("No kind with description Material Status found");
        }
      }catch(error){
        setError("Failed to fetch Status");
      }
    };
    fetchMaterialStatus();
    const fetchDoctorDescriptions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/doctor`
        );
        const data = await response.json();
        setDoctorIdOptions(
          data.map((doctor) => ({ id: doctor.doctorId, name: doctor.docName }))
        );
      } catch (error) {
        setError("Failed to fetch doctors");
      }
    };
    fetchDoctorDescriptions();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const currentDate = new Date().toISOString();
    setEntryDate(currentDate);
    setUpdateDate(currentDate);
    const userData = {
      fname,
      mname,
      lname,
      doctorId,
      dob,
      sex,
      bloodGroup,
      materialStatus,
      job,
      jobDescription,
      smoking,
      phone,
      notes,
      entryUser,
      updateUser,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/patient`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to create patient");
      }
      setSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      setError(error.message || "An error occurred. Please try again later.");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
      style={{ textAlign: "center", maxWidth: "600px", margin: "auto" }}
    >
      <h2>Create Patient</h2>
      {success && (
        <p style={{ color: "green" }}>Patient created successfully!</p>
      )}
      <form onSubmit={handleSubmit}>
        <Flex gap="20px">
          <FormControl>
            <FormLabel>First Name</FormLabel>
            <Input
              placeholder="First Name"
              value={fname}
              onChange={(e) => setFname(e.target.value)}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Middle Name</FormLabel>
            <Input
              placeholder="Middle Name"
              value={mname}
              onChange={(e) => setMname(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Last Name</FormLabel>
            <Input
              placeholder="Last Name"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              required
            />
          </FormControl>
        </Flex>
        <Flex gap="20px">
          <FormControl>
            <FormLabel>Phone</FormLabel>
            <Input
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>DOB</FormLabel>
            <Input
              type="date"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Sex</FormLabel>
            <Select
              placeholder="Select"
              id="sex"
              name="sex"
              value={sex}
              onChange={(e) => setSex(e.target.value)}
            >
              {sexOptions.map((option) => (
                <option key={option.idCode} value={option.description}>
                  {option.description}
                </option>
              ))}
            </Select>
          </FormControl>
        </Flex>
        <Flex gap="20px">
          <FormControl>
            <FormLabel>Blood Group</FormLabel>
            <Select
              placeholder="Select"
              id="sex"
              name="sex"
              value={bloodGroup}
              onChange={(e) => setBloodGroup(e.target.value)}
            >
              {bloodOptions.map((option) => (
                <option key={option.idCode} value={option.description}>
                  {option.description}
                </option>
              ))}
            </Select>{" "}
          </FormControl>
           <FormControl>
            <FormLabel>Material Status</FormLabel>
            <Select
              placeholder="Select"
              id="sex"
              name="sex"
              value={materialStatus}
              onChange={(e) => setMaterialStatus(e.target.value)}
            >
              {statusOptions.map((option) => (
                <option key={option.idCode} value={option.description}>
                  {option.description}
                </option>
              ))}
            </Select>{" "}
          </FormControl>
          <FormControl>
            <FormLabel>Notes</FormLabel>
            <Input
              placeholder="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </FormControl>
        </Flex>
        <Flex gap="20px">
          <FormControl>
            <FormLabel>Job Description</FormLabel>
            <Input
              placeholder="Job Description"
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Job</FormLabel>
            <Checkbox
              isChecked={job}
              onChange={(e) => setJob(e.target.checked)}
            >
              Is Employed?
            </Checkbox>
          </FormControl>
          <FormControl>
            <FormLabel>Smoking</FormLabel>
            <Checkbox
              isChecked={smoking}
              onChange={(e) => setSmoking(e.target.checked)}
            >
              Smoker?
            </Checkbox>
          </FormControl>
        </Flex>
        <FormControl>
          <FormLabel>Doctor</FormLabel>
          <Select
            variant="filled"
            placeholder="Select Doctor"
            value={doctorId}
            onChange={(e) => setDoctorId(e.target.value)}
            mb={4}
            required
          >
            {doctorIdOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </Select>
        </FormControl>

        {/* Additional fields as needed */}
        <Button colorScheme="blue" type="submit">
          Submit
        </Button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </motion.div>
  );
};

export default CreatePatientForm;
