import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone, MdCancel } from "react-icons/md";
import Card from "components/card/Card";

const ListRole = () => {
  const [roles, setRoles] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [error, setError] = useState("");
  const [editableRole, setEditableRole] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [updateUser, setupdateUser] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // Split the token into header, payload, and signature
      // eslint-disable-next-line no-unused-vars
      const [header, payload, signature] = accessToken.split(".");
      // Decode the payload (Base64 decoded)
      const decodedPayload = JSON.parse(atob(payload));
      // Extract the username
      const updateUser = decodedPayload.username;
      // Set the entryUser in the state
      setupdateUser(updateUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchListRoles = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/role`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch roles");
        }
        const data = await response.json();
        setRoles(data);
        setFilteredRoles(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchListRoles();
  }, []);

  const handleDeleteRole = async (roleId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/role`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ roleId }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete role");
      }
      // Remove the deleted role from the roles state
      setRoles(roles.filter((role) => role.roleId !== roleId));
      setFilteredRoles(filteredRoles.filter((role) => role.roleId !== roleId));
    } catch (error) {
      console.error("Error deleting role:", error);
      setError("Failed to delete role");
    }
  };

  const handleEditRole = (role) => {
    setEditableRole(role);
  };

  const handleSaveRole = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/role`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            roleId: editableRole.roleId,
            description: editableRole.description,
            updateUser: updateUser,
          }),
        }
      );
      console.log(editableRole);
      if (!response.ok) {
        throw new Error("Failed to update role");
      }
      // Update roles in the local state
      setRoles(
        roles.map((role) => {
          if (role.roleId === editableRole.roleId) {
            return editableRole;
          }
          return role;
        })
      );
      setFilteredRoles(
        filteredRoles.map((role) => {
          if (role.roleId === editableRole.roleId) {
            return editableRole;
          }
          return role;
        })
      );
      setEditableRole(null);

      // Clear the editable role after saving
    } catch (error) {
      console.error("Error updating role:", error);
      setError("Failed to update role");
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = roles.filter((role) =>
      role.description.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredRoles(filtered);
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
      boxShadow="lg"
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color="black" fontSize="22px" fontWeight="700" lineHeight="100%">
          Role List
        </Text>
        {error && <Text color="red.500">{error}</Text>}

        <Input
          placeholder="Search role..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>
      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr bg="blue.500" textColor="white">
              <Th textColor="white">Role</Th>
              <Th textColor="white">Description</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredRoles.map((role, index) => (
              <Tr key={role.roleId}>
                <Td>{index + 1}</Td>
                <Td>
                  {editableRole && editableRole.roleId === role.roleId ? (
                    <input
                      type="text"
                      value={editableRole.description}
                      onChange={(e) =>
                        setEditableRole({
                          ...editableRole,
                          description: e.target.value,
                        })
                      }
                    />
                  ) : (
                    role.description
                  )}
                </Td>
                <Td>
                  {editableRole && editableRole.roleId === role.roleId ? (
                    <>
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSaveRole}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdCancel />}
                        colorScheme="red"
                        ml={2}
                        size="sm"
                        onClick={() => setEditableRole(null)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        leftIcon={<MdEdit />}
                        colorScheme="blue"
                        size="sm"
                        onClick={() => handleEditRole(role)}
                      >
                        Edit
                      </Button>
                      <Button
                        leftIcon={<MdDelete />}
                        colorScheme="red"
                        size="sm"
                        ml={2}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this role?"
                            )
                          ) {
                            handleDeleteRole(role.roleId);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
};
export default ListRole;
