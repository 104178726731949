import React, { useState, useEffect } from "react";
import { Button, Select, Input } from "@chakra-ui/react";
import { motion } from "framer-motion";

const CreateStyleForm = () => {
  const [scientistOptions, setScientistOptions] = useState([]); // Assuming you fetch this data
  const [styleId, setStyleId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [scientist, setScientist] = useState("");
  const [entryUser, setEntryUser] = useState("");
  const [entryDate, setEntryDate] = useState("");
  const [updateUser, setUpdateUser] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const username = decodedPayload.username;
      setEntryUser(username);
      setUpdateUser(username);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const currentDate = new Date().toISOString();
    setEntryDate(currentDate);
    setUpdateDate(currentDate);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/style`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            description,
            scientist,
            entryUser,
            updateUser,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to create style");
      }
      setSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      setError(error.message || "An error occurred. Please try again later.");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
      style={{ textAlign: "center", maxWidth: "400px", margin: "auto" }}
    >
      <h2>Create Style</h2>
      {success && <p style={{ color: "green" }}>Style created successfully!</p>}
      <form onSubmit={handleSubmit}>
        <Input
          variant="filled"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          mb={4}
          required
        />
        <Input
          variant="filled"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          mb={4}
          required
        />
        <Input
          variant="filled"
          placeholder="Scientist"
          value={scientist}
          onChange={(e) => setScientist(e.target.value)}
          mb={4}
          required
        />
        {/* <Select
                    variant="filled"
                    placeholder="Select Scientist"
                    value={scientist}
                    onChange={(e) => setScientist(e.target.value)}
                    mb={4}
                    required
                >
                    {scientistOptions.map(option => (
                        <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                </Select> */}
        <Button colorScheme="blue" type="submit">
          Submit
        </Button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </motion.div>
  );
};

export default CreateStyleForm;
