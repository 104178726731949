import React, { useState } from 'react';
import { Button, Box, Flex, Center } from "@chakra-ui/react";
import { MdAdd, MdRemove } from 'react-icons/md';
import KindList from "./components/KindList";
import CreateKindForm from "./components/CreateKind";
import CodeList from "./components/CodeList";
import CreateCode from "./components/CreateCode";

const Code = () => {
  const [showCreateKindForm, setShowCreateKindForm] = useState(false);
  const [showCreateCodeForm, setShowCreateCodeForm] = useState(false);

  const toggleCreateKindForm = () => {
    setShowCreateKindForm(!showCreateKindForm);
  };

  const toggleCreateCodeForm = () => {
    setShowCreateCodeForm(!showCreateCodeForm);
  };

  return (
    <Flex direction="column" alignItems="center">
     
      <Box width="80%" maxW="lg" mt={"35px"}>
        <Center>
          <Button
            colorScheme={showCreateKindForm ? "red" : "blue"}
            leftIcon={showCreateKindForm ? <MdRemove /> : <MdAdd />}
            onClick={toggleCreateKindForm}
            marginBottom="20px"
            mt={"10"}
          >
            {showCreateKindForm ? "Hide Create Kind Form" : "Create Kind"}
          </Button>
        </Center>
        {showCreateKindForm && <CreateKindForm />}
      </Box>
      <Box width="80%" maxW="xxl" mt={12}>
        <KindList />
      </Box>
     
      <Box width="80%" maxW="lg">
        <Center>
          <Button
            colorScheme={showCreateCodeForm ? "red" : "blue"}
            leftIcon={showCreateCodeForm ? <MdRemove /> : <MdAdd />}
            onClick={toggleCreateCodeForm}
            marginBottom="20px"
            mt={"10"}
          >
            {showCreateCodeForm ? "Hide Create Code Form" : "Create Code"}
          </Button>
        </Center>
        {showCreateCodeForm && <CreateCode />}
      </Box>
      <Box width="80%" maxW="xxl">
        <CodeList />
      </Box>
    </Flex>
  );
};

export default Code;
