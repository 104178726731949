import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone } from "react-icons/md";
import Card from "components/card/Card";
import moment from "moment";

export const ListDoctor = () => {
  const [doctors, setDoctors] = useState([]);
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [error, setError] = useState("");
  const [editableDoctor, setEditableDoctor] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [editMode,setEditMode] = useState(false);
  const [updateUser, setUpdateUser] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const toast = useToast();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const entryUser = decodedPayload.username;
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor`);
        if (!response.ok) {
          throw new Error("Failed to fetch doctors");
        }
        const data = await response.json();
        setDoctors(data);
        setFilteredDoctors(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchDoctors();
  }, []);

  // Delete Doctor
  const handleDeleteDoctor = async (doctorId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ doctorId }),
      });
      if (!response.ok) {
        throw new Error("Failed to delete doctor");
      }
      setDoctors(doctors.filter((doctor) => doctor.doctorId !== doctorId));
      setFilteredDoctors(filteredDoctors.filter((doctor) => doctor.doctorId !== doctorId));
      toast({
        title: "Doctor deleted.",
        description: `Doctor has been deleted.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setError("Failed to delete doctor: " + error.message);
    }
  };

  // Edit Doctor
  const handleEditDoctor = (doctor) => {
    setEditableDoctor(doctor);
  };

  // Save Doctor
  const handleSaveDoctor = async () => {
    try {
      const { doctorId, docName, username, password, phone } = editableDoctor;

      const encryptedPassword = password ? password : undefined;

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          doctorId:editableDoctor.doctorId,
          docName:editableDoctor.docName,
          username:editableDoctor.username,
          password: encryptedPassword,
          phone:editableDoctor.phone,
          updateUser:updateUser,
          //updateDate: moment().format('YYYY-MM-DD HH:mm:ss'), 
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to update doctor: ${JSON.stringify(errorData)}`);
      }

      const updatedDoctor = await response.json();
      setDoctors(doctors.map((doctor) => {
        if (doctor.doctorId === updatedDoctor.doctorId) {
          return updatedDoctor;
        }
        return doctor;
      }));
      setFilteredDoctors(filteredDoctors.map((doctor) => {
        if (doctor.doctorId === updatedDoctor.doctorId) {
          return updatedDoctor;
        }
        return doctor;
      }));

      setEditableDoctor(null);
      toast({
        title: "Doctor updated.",
        description: `Doctor ${updatedDoctor.docName} has been updated.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setError(error.message);
      toast({
        title: "Update failed.",
        description: `There was an error updating the doctor: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = doctors.filter((doctor) =>
      doctor.docName.toLowerCase().includes(query) ||
      doctor.username.toLowerCase().includes(query)
    );
    setFilteredDoctors(filtered);
  };

  const handleViewDoctor = (doctor) => {
    setSelectedDoctor(doctor);
    setEditMode(false);
  };

  const handleCloseModal = () => {
    setSelectedDoctor(null);
    setEditMode(false);
  };

  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color="gray.800" fontSize="22px" fontWeight="700" lineHeight="100%">
          Doctor List
        </Text>
        <Input
          placeholder="Search doctor..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>
      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr bg="blue.500" textColor="white">
              <Th textColor="white">ID</Th>
              <Th textColor="white">Doctor Name</Th>
              <Th textColor="white">Username</Th>
              <Th textColor="white">Phone</Th>
              <Th textColor="white">Password</Th>
              <Th textColor="white">Entry User</Th>
              <Th textColor="white">Entry Date</Th>
              <Th textColor="white">Update User</Th>
              <Th textColor="white">Update Date</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredDoctors.map((doctor, index) => (
              <Tr key={doctor.doctorId}>
                <Td>{index + 1}</Td>
                <Td>{doctor.docName}</Td>
                <Td>{doctor.username}</Td>
                <Td>{doctor.phone}</Td>
                <Td>...</Td>
                <Td>{doctor.entryUser}</Td>
                <Td>{doctor.entryDate}</Td>
                <Td>{doctor.updateUser}</Td>
                <Td>{doctor.updateDate}</Td>
                <Td>
                  <Button
                    leftIcon={<MdEdit />}
                    colorScheme="blue"
                    size="sm"
                    onClick={() => handleViewDoctor(doctor)}
                  >
                    Edit
                  </Button>
                  <Button
                    leftIcon={<MdDelete />}
                    colorScheme="red"
                    size="sm"
                    ml={2}
                    onClick={() => {
                      if (window.confirm("Are you sure you want to delete this doctor?")) {
                        handleDeleteDoctor(doctor.doctorId);
                      }
                    }}
                  >
                    Delete
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    
      {selectedDoctor && (
        <Modal isOpen={true} onClose={handleCloseModal} size="xl">
          <ModalOverlay />
          <ModalContent
            display="flex"
            alignItems="center"
            justifyContent="center"
            animation="fadeIn 0.3s ease-out"
          >
            <ModalHeader>View Doctor</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box text>
                <Flex direction="column" mb="20px">
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={9}>
                        Name:
                      </Text>
                      <Flex>
                        {editableDoctor && editableDoctor.doctorId === selectedDoctor.doctorId ? (
                          <Input
                            value={editableDoctor.docName}
                            onChange={(e) =>
                              setEditableDoctor({
                                ...editableDoctor,
                                docName: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedDoctor.docName}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={5}>
                        Username:
                      </Text>
                      <Flex>
                        {editableDoctor && editableDoctor.doctorId === selectedDoctor.doctorId ? (
                          <Input
                            value={editableDoctor.username}
                            onChange={(e) =>
                              setEditableDoctor({
                                ...editableDoctor,
                                username: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedDoctor.username}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={9}>
                        Password:
                      </Text>
                      <Flex>
                        {editableDoctor && editableDoctor.doctorId === selectedDoctor.doctorId ? (
                          <Input
                            type="password"
                            
                            onChange={(e) =>
                              setEditableDoctor({
                                ...editableDoctor,
                                password: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={9}>
                        Phone:
                      </Text>
                      <Flex>
                        {editableDoctor && editableDoctor.doctorId === selectedDoctor.doctorId ? (
                          <Input
                            value={editableDoctor.phone}
                            onChange={(e) =>
                              setEditableDoctor({
                                ...editableDoctor,
                                phone: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedDoctor.phone}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={9}>
                        Entry User:
                      </Text>
                      <Flex>
                        {selectedDoctor.entryUser}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={9}>
                        Entry Date:
                      </Text>
                      <Flex>
                        {selectedDoctor.entryDate}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={9}>
                        Update User:
                      </Text>
                      <Flex>
                        {editableDoctor && editableDoctor.doctorId === selectedDoctor.doctorId ? (
                          <Input
                            value={editableDoctor.updateUser}
                            isReadOnly
                          />
                        ) : (
                          <>{selectedDoctor.updateUser}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={9}>
                        Update Date:
                      </Text>
                      <Flex>
                        {editableDoctor && editableDoctor.doctorId === selectedDoctor.doctorId ? (
                          <Input
                            value={editableDoctor.updateDate}
                            isReadOnly
                          />
                        ) : (
                          <>{selectedDoctor.updateDate}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                </Flex>
              </Box>
            </ModalBody>
            <ModalFooter>
              {editableDoctor && editableDoctor.doctorId === selectedDoctor.doctorId ? (
                <>
                  <Button
                    colorScheme="blue"
                    onClick={handleSaveDoctor}
                    mr={3}
                    leftIcon={<MdDone />}
                  >
                    Save
                  </Button>
                  <Button colorScheme="red" onClick={() => setEditableDoctor(null)}>
                    Cancel
                  </Button>
                </>
              ) : (
                <Button colorScheme="blue" onClick={() => setEditableDoctor(selectedDoctor)}>
                  Edit
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Card>
  );
};
