import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Input,
  Flex,
  Icon,
  Select,
} from "@chakra-ui/react";
import { MdEdit, MdDelete, MdDone, MdCancel } from "react-icons/md";
import Card from "components/card/Card";

const KindList = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState("");
  const [editableKind, setEditableKind] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseKinds = await fetch(
          `${process.env.REACT_APP_API_URL}/api/kind`
        );
        const responseCodes = await fetch(
          `${process.env.REACT_APP_API_URL}/api/code`
        );

        if (!responseKinds.ok || !responseCodes.ok) {
          throw new Error("Failed to fetch data");
        }

        const kinds = await responseKinds.json();
        const codes = await responseCodes.json();

        // Assuming each code has a field 'idCodeKind' representing the kind it belongs to
        const data = kinds.map((kind) => {
          const associatedCodes = codes.filter(
            (code) => code.idCodeKind === kind.idCodeKind
          );
          return { ...kind, codes: associatedCodes };
        });

        setData(data);
        setFilteredData(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  const handleEditKind = (kind) => {
    setEditableKind(kind);
  };

  const handleSaveKind = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/kind`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editableKind),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update kind");
      }

      const updatedKind = await response.json();
      setData((prevData) =>
        prevData.map((item) =>
          item.idCodeKind === updatedKind.idCodeKind ? updatedKind : item
        )
      );
      setFilteredData((prevFilteredData) =>
        prevFilteredData.map((item) =>
          item.idCodeKind === updatedKind.idCodeKind ? updatedKind : item
        )
      );

      setEditableKind(null);
    } catch (error) {
      console.error("Error updating kind:", error);
      setError("Failed to update kind");
    }
  };

  const handleDeleteKind = async (idCodeKind) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/kind`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idCodeKind,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete kind");
      }

      setData((prevData) =>
        prevData.filter((item) => item.idCodeKind !== idCodeKind)
      );
      setFilteredData((prevFilteredData) =>
        prevFilteredData.filter((item) => item.idCodeKind !== idCodeKind)
      );
    } catch (error) {
      console.error("Error deleting kind:", error);
      setError("Failed to delete kind");
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = data.filter((item) =>
      item.description.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
  };

  return (
    <Card
      mt="50px"
      boxShadow="xl"
      p="6"
      borderRadius="md"
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Box
        px="25px"
        mb="20px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading mb={1}>Code Kinds</Heading>
        <Input
          placeholder="Search by description..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Box>
      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="black" mb="24px">
          <Thead>
            <Tr bg="blue.500" textColor="white">
              <Th></Th>
              <Th textColor="white">Index</Th>
              <Th textColor="white">Description</Th>
              <Th textColor="white">Codes</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((item, index) => (
              <Tr key={item.idCodeKind}>
                <Td></Td>
                <Td>{index + 1}</Td>
                <Td>
                  {editableKind &&
                  editableKind.idCodeKind === item.idCodeKind ? (
                    <Input
                      value={editableKind.description}
                      onChange={(e) =>
                        setEditableKind({
                          ...editableKind,
                          description: e.target.value,
                        })
                      }
                      size="sm"
                    />
                  ) : (
                    item.description
                  )}
                </Td>
                <Td>
                  <Select
                    size="sm"
                    isDisabled={
                      editableKind &&
                      editableKind.idCodeKind === item.idCodeKind
                    }
                  >
                    {item.codes.map((code) => (
                      <option key={code.idCode} value={code.idCode}>
                        {code.description}
                      </option>
                    ))}
                  </Select>
                </Td>
                <Td>
                  {editableKind &&
                  editableKind.idCodeKind === item.idCodeKind ? (
                    <Flex>
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSaveKind}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdCancel />}
                        colorScheme="red"
                        ml={2}
                        size="sm"
                        onClick={() => setEditableKind(null)}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  ) : (
                    <Flex>
                      <Button
                        colorScheme="blue"
                        width={10}
                        size="sm"
                        onClick={() => handleEditKind(item)}
                      >
                        <Icon as={MdEdit} boxSize={5} />
                      </Button>

                      <Button
                        colorScheme="red"
                        size="sm"
                        width={10}
                        ml={2}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this kind?"
                            )
                          ) {
                            handleDeleteKind(item.idCodeKind);
                          }
                        }}
                      >
                        <Icon as={MdDelete} boxSize={5} />
                      </Button>
                    </Flex>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
};

export default KindList;
