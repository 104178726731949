import React, { useState, useEffect } from "react";
import { Button, Select } from "@chakra-ui/react";
import { motion } from "framer-motion";

const RolePermissionForm = () => {
  const [roleIdOptions, setRoleIdOptions] = useState([]);
  const [permissionIdOptions, setPermissionIdOptions] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [permissionId, setPermissionId] = useState("");
  const [entryUser, setEntryUser] = useState("");
  const [updateUser, setUpdateUser] = useState("");
  const [success, setSuccess] = useState(false);

  const [error, setError] = useState("");
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    console.log("Access Token:", accessToken);

    if (accessToken) {
      // Split the token into header, payload, and signature
      // eslint-disable-next-line no-unused-vars
      const [header, payload, signature] = accessToken.split(".");
      // Decode the payload (Base64 decoded)
      const decodedPayload = JSON.parse(atob(payload));
      // Extract the username
      const entryUser = decodedPayload.username;
      // Set the entryUser in the state
      setEntryUser(entryUser);
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []); // Run this effect only once, similar to componentDidMount

  useEffect(() => {
    const fetchRoleAndPermissionDescriptions = async () => {
      try {
        const roleResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/role`
        );
        const roleData = await roleResponse.json();
        setRoleIdOptions(
          roleData.map((role) => ({
            id: role.roleId,
            description: role.description,
          }))
        );

        const permissionResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/permission`
        );
        const permissionData = await permissionResponse.json();
        setPermissionIdOptions(
          permissionData.map((permission) => ({
            id: permission.permissionId,
            description: permission.description,
          }))
        );
      } catch (error) {
        setError("Failed to fetch role and permission descriptions");
      }
    };

    fetchRoleAndPermissionDescriptions();
  }, []);

  const handleSubmit = async (event) => {
    // Check if entryUser is available
    if (!entryUser) {
      console.error("Entry user not available.");
      return;
    }
    event.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/rolePermission`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            roleId,
            permissionId,
            entryUser,
            updateUser: entryUser,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to add role permission");
      }
      setRoleId("");
      setPermissionId("");
      setUpdateUser("");
      setSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      setError(error.message || "An error occurred. Please try again later.");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
      style={{ textAlign: "center", maxWidth: "400px", margin: "auto" }}
    >
      <h2 style={{ color: "#333" }}>Add Role Permission</h2>
      {success && (
        <p style={{ color: "green", textAlign: "center" }}>
          Role added successfully!
        </p>
      )}

      <form onSubmit={handleSubmit}>
        <Select
          variant="filled"
          placeholder="Select Role"
          value={roleId}
          onChange={(e) => setRoleId(e.target.value)}
          mb={4}
          required
        >
          {roleIdOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.description}
            </option>
          ))}
        </Select>

        <Select
          variant="filled"
          placeholder="Select Permission"
          value={permissionId}
          onChange={(e) => setPermissionId(e.target.value)}
          mb={4}
          required
        >
          {permissionIdOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.description}
            </option>
          ))}
        </Select>

        {/* <Input
                    variant="filled"
                    placeholder="Entry User"
                    value={entryUser}
                    onChange={(e) => setEntryUser(e.target.value)}
                    mb={4}
                    required
                /> */}

        {/* <Input
                    variant="filled"
                    placeholder="Update User"
                    value={updateUser}
                    onChange={(e) => setUpdateUser(e.target.value)}
                    mb={4}
                    required
                /> */}

        <Button colorScheme="blue" type="submit">
          Submit
        </Button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </motion.div>
  );
};

export default RolePermissionForm;
