import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { motion } from "framer-motion";

const initialValue = {
  name: "",
  description: "",
  code: "",
};

const CreateSchool = () => {
  const [name, setSchoolName] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [schoolData, setSchoolData] = useState(initialValue);
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState("");
  const [entryUser, setEntryUser] = useState("");
  const [updateUser, setUpdateUser] = useState("");

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/school`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            description,
            code,
            entryUser,
            updateUser,
          }),
        }
      );

      if (response.ok) {
        setSchoolData(initialValue);
        setSuccessMessage(true);
        const data = await response.json();
        console.log("School created");
      } else {
        const errorData = await response.json();
        setError(
          errorData.error || "An error occurred. Please try again later."
        );
      }
    } catch (error) {
      setError(error.message || "An error occurred. Please try again later.");
    }
  };
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const username = decodedPayload.username;
      setEntryUser(username);
      setUpdateUser(username);
    } else {
      console.error("Access token not found.");
    }
  });
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
    >
      <Flex direction="column" minH="100vh" align="center" justify="center">
        <Box
          zIndex="0"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Card
            p="30px"
            boxShadow="md"
            borderRadius="md"
            w={{ sm: "330px", md: "700px", lg: "850px" }}
          >
            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
              School Info
            </Text>
            {successMessage && (
              <Text color="green.500" mb="20px">
                School created successfully!
              </Text>
            )}
            {error && (
              <Text color="red.500" mb="20px">
                {error}
              </Text>
            )}
            <Flex direction="column" w="100%">
              <Flex direction="row" justify="space-between" mb="20px">
                <Box mb="20px" flex="1" mr="10px">
                  <Text fontSize="sm" color="gray.500" mb="2">
                    School Name
                  </Text>
                  <Input
                    mb="0px"
                    id="name"
                    placeholder="e.g., School 1"
                    name="name"
                    value={name}
                    onChange={(e) => setSchoolName(e.target.value)}
                  />
                </Box>
                <Box mb="20px" flex="1" ml="10px">
                  <Text fontSize="sm" color="gray.500" mb="2">
                    Code
                  </Text>
                  <Input
                    mb="0px"
                    id="code"
                    placeholder="e.g., 20215"
                    name="code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Box>
              </Flex>
              <Box mb="20px">
                <Text fontSize="sm" color="gray.500" mb="2">
                  Description
                </Text>
                <Textarea
                  mb="0px"
                  id="description"
                  name="description"
                  placeholder="Enter description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Box>
              <Flex justify="center" mt="24px">
                <Button
                  colorScheme="blue"
                  fontSize="m"
                  borderRadius="md"
                  w={{ base: "128px", md: "148px" }}
                  h="46px"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Flex>
            </Flex>
          </Card>
        </Box>
      </Flex>
    </motion.div>
  );
};

export default CreateSchool;
