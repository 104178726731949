import React, { useState, useEffect } from "react";
import { Button, Input, Select, Flex, FormControl, FormLabel, Checkbox } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import bcrypt from 'bcryptjs'; // Make sure to install this package using `npm install bcryptjs`

export const AddDoctor = () => {
    const [doctorId, setDoctorId] = useState('');
    const [docName, setDocName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [entryUser, setEntryUser] = useState('');
    const [updateUser, setUpdateUser] = useState('');
    const [entryDate, setEntryDate] = useState('');
    const [updateDate, setUpdateDate] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            const [header, payload, signature] = accessToken.split('.');
            const decodedPayload = JSON.parse(atob(payload));
            const username = decodedPayload.username;
            setEntryUser(username);
            setUpdateUser(username);
        } else {
            console.error("Access token not found.");
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const currentDate = new Date().toISOString();
        setEntryDate(currentDate);
        setUpdateDate(currentDate);
        const hashedpassword = await bcrypt.hash(password, 10);
        const Data = {
            docName,
            username,
            phone,
            password: hashedpassword,
            entryUser:entryUser,
            updateUser:updateUser
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Data),
            });
            if (!response.ok) {
                throw new Error('Failed to create Doctor');
            }
            setSuccess(true);
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } catch (error) {
            setError(error.message || 'An error occurred. Please try again later.');
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            style={{ textAlign: 'center', maxWidth: '600px', margin: 'auto' }}>
            <h2>Create Doctor</h2>
            {success && <p style={{ color: 'green' }}>Doctor created successfully!</p>}
            <form onSubmit={handleSubmit}>
                <Flex gap="20px">
                    <FormControl>
                        <FormLabel>Doctor Name</FormLabel>
                        <Input
                            placeholder="Doctor Name"
                            value={docName}
                            onChange={(e) => setDocName(e.target.value)}
                            required
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>UserName</FormLabel>
                        <Input
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </FormControl>
                </Flex>
                <Flex gap="20px">
                    <FormControl>
                        <FormLabel>Password</FormLabel>
                        <Input
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Phone</FormLabel>
                        <Input
                            placeholder="Phone Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                    </FormControl>
                </Flex>
                <Button colorScheme="blue" type="submit">
                    Submit
                </Button>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </motion.div>
    );
};
