import React, { useState, useEffect } from "react";
import { Button, Select, Input } from "@chakra-ui/react"; // Make sure to import Input
import { motion } from "framer-motion";

const PromptForm = () => {
  const [doctorIdOptions, setDoctorIdOptions] = useState([]); // Renamed for consistency
  const [doctorId, setDoctorId] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [entryUser, setEntryUser] = useState("");
  const [updateUser, setUpdateUser] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const username = decodedPayload.username;
      setEntryUser(username);
      setUpdateUser(username);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchDoctorDescriptions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/doctor`
        );
        const data = await response.json();
        setDoctorIdOptions(
          data.map((doctor) => ({ id: doctor.doctorId, name: doctor.docName }))
        );
      } catch (error) {
        setError("Failed to fetch doctors");
      }
    };
    fetchDoctorDescriptions();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!entryUser) {
      console.error("Entry user not available.");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/prompt`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            doctorId,
            description,
            content,
            entryUser,
            updateUser: entryUser,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to add prompt");
      }
      setDoctorId("");
      setDescription("");
      setContent("");
      setUpdateUser("");
      setSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      setError(error.message || "An error occurred. Please try again later.");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
      style={{ textAlign: "center", maxWidth: "400px", margin: "auto" }}
    >
      <h2>Add Prompt</h2>
      {success && <p style={{ color: "green" }}>Prompt added successfully!</p>}
      <form onSubmit={handleSubmit}>
        <Input
          variant="filled"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          mb={4}
          required
        />
        <Input
          variant="filled"
          placeholder="Content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          mb={4}
          required
        />
        <Select
          variant="filled"
          placeholder="Select Doctor"
          value={doctorId}
          onChange={(e) => setDoctorId(e.target.value)}
          mb={4}
          required
        >
          {doctorIdOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </Select>
        <Button colorScheme="blue" type="submit">
          Submit
        </Button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </motion.div>
  );
};

export default PromptForm;
