import React, { createContext, useContext, useState } from 'react';

const SessionContext = createContext();

export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
  const [sessionData, setSessionData] = useState(null);

  const startSession = (data) => {
    setSessionData(data);
  };

  const endSession = () => {
    // Do not clear sessionData here to retain values
    // Optionally, you could update other session status states if needed
  };

  const clearSession = () => {
    setSessionData(null);
  };

  return (
    <SessionContext.Provider value={{ sessionData, startSession, endSession, clearSession }}>
      {children}
    </SessionContext.Provider>
  );
};
