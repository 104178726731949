import { MdDelete, MdEdit, MdDone, MdCancel } from "react-icons/md";
import { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Input,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Button,
  Select,
  Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card";

const PromptList = () => {
  const [Prompts, setPrompts] = useState([]);
  const [filteredPrompt, setFilteredPrompt] = useState([]);
  const [error, setError] = useState("");
  const [editablePrompt, setEditablePrompt] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [updateUser, setUpdateUser] = useState("");
  const [originalPrompt, setOriginalPrompt] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    console.log("Access Token:", accessToken);

    if (accessToken) {
      // Split the token into header, payload, and signature
      // eslint-disable-next-line no-unused-vars
      const [header, payload, signature] = accessToken.split(".");
      // Decode the payload (Base64 decoded)
      const decodedPayload = JSON.parse(atob(payload));
      // Extract the username
      const entryUser = decodedPayload.username;
      // Set the entryUser in the state
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []); // Run this effect only once, similar to componentDidMount
  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/prompt`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch prompt");
        }
        const data = await response.json();
        const updatePrompt = data.map(async (Prompt) => {
          const doctordescription = await fetchDoctorDescription(
            Prompt.doctorId
          );
          return { ...Prompt, doctordescription };
        });
        const resolvedRolePermissions = await Promise.all(updatePrompt);
        setPrompts(resolvedRolePermissions);
        setFilteredPrompt(resolvedRolePermissions);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchPrompts();
  }, []);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/doctor`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch doctor");
        }
        const data = await response.json();
        setDoctors(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchDoctors();
  }, []);

  const handleDeletePrompt = async (promptId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/prompt`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ promptId }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete prompt");
      }
      // Remove the deleted role permission from the state
      setPrompts(Prompts.filter((prompt) => prompt.promptId !== promptId));
      setFilteredPrompt(
        filteredPrompt.filter((prompt) => prompt.promptId !== promptId)
      );
    } catch (error) {
      console.error("Error deleting prompt:", error);
      setError("Failed to delete  prompt");
    }
  };
  const fetchDoctorDescription = async (doctorId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/doctor/${doctorId}`
      );

      if (!response.ok) {
        const errorData = await response.json(); // Parse error message from the server if available
        throw new Error(
          `Failed to fetch doctor: ${errorData.error || "Unknown error"}`
        );
      }

      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        return data[0].docName;
      } else {
        throw new Error("Doctor name not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };

  const handleEditPrompt = (prompt) => {
    setEditablePrompt(prompt);
    setOriginalPrompt(prompt); // Save the original data for comparison later
  };

  const handleSavePrompt = async () => {
    try {
      const data = {
        promptId: editablePrompt.promptId,
        doctorId: editablePrompt.doctorId, // Assuming doctorId might change and should always be sent
        UpdateUser: updateUser, // Assuming this should always be sent
      };

      // Only add description to the payload if it has been edited
      if (editablePrompt.description !== originalPrompt.description) {
        data.description = editablePrompt.description;
      }

      // Only add content if it has been edited
      if (editablePrompt.content !== originalPrompt.content) {
        data.content = editablePrompt.content;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/prompt`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const errorData = await response.json(); // Parse the response JSON for error message
        throw new Error(
          `Failed to update prompt: ${errorData.message || "Unknown error"}`
        );
      }

      const updatedData = await response.json();
      setPrompts((prevPrompts) =>
        prevPrompts.map((prompt) =>
          prompt.promptId === editablePrompt.promptId
            ? { ...editablePrompt, ...updatedData }
            : prompt
        )
      );
      setFilteredPrompt((prevFilteredPrompts) =>
        prevFilteredPrompts.map((prompt) =>
          prompt.promptId === editablePrompt.promptId
            ? { ...editablePrompt, ...updatedData }
            : prompt
        )
      );

      setEditablePrompt(null);
      setOriginalPrompt(null); // Clear original prompt data
    } catch (error) {
      console.error("Error updating prompt:", error);
      setError(error.message); // Set the error in state to be displayed
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = Prompts.filter(
      (prompt) =>
        prompt.description.toLowerCase().includes(query.toLowerCase()) ||
        prompt.doctordescription.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredPrompt(filtered);
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        {error && <Text color="red.500">{error}</Text>}

        <Text
          color="gray.800"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Prompt List
        </Text>
        <Input
          placeholder="Search prompt..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>
      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr bg="blue.500">
              <Th textColor="white">Index</Th>
              <Th textColor="white">Doctor</Th>
              <Th textColor="white">description</Th>
              <Th textColor="white">content</Th>

              <Th textColor="white">Entry User</Th>
              <Th textColor="white">Entry Date</Th>
              <Th textColor="white">Update User</Th>
              <Th textColor="white">Update Date</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredPrompt.map((prompt, index) => (
              <Tr key={prompt.promptId}>
                <Td>{index + 1}</Td>

                <Td>
                  {editablePrompt &&
                  editablePrompt.promptId === prompt.promptId ? (
                    <Select
                      width={"100px"}
                      value={editablePrompt.doctorId}
                      onChange={(e) =>
                        setEditablePrompt({
                          ...editablePrompt,
                          doctorId: e.target.value,
                        })
                      }
                    >
                      {doctors.map((doctor) => (
                        <option key={doctor.doctorId} value={doctor.doctorId}>
                          {doctor.docName}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    prompt.doctordescription
                  )}
                </Td>
                <Td>
                  <Flex>
                    {editablePrompt &&
                    editablePrompt.promptId === prompt.promptId ? (
                      <Input
                        width={"100px"}
                        value={editablePrompt.description}
                        onChange={(e) =>
                          setEditablePrompt({
                            ...editablePrompt,
                            description: e.target.value,
                          })
                        }
                      />
                    ) : (
                      prompt.description
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {editablePrompt &&
                    editablePrompt.promptId === prompt.promptId ? (
                      <Input
                        width={"100px"}
                        value={editablePrompt.content}
                        onChange={(e) =>
                          setEditablePrompt({
                            ...editablePrompt,
                            content: e.target.value,
                          })
                        }
                      />
                    ) : (
                      prompt.content
                    )}
                  </Flex>
                </Td>
                <Td>{prompt.entryUser}</Td>
                <Td>{prompt.entryDate.substring(0, 10)}</Td>

                <Td>{prompt.updateUser}</Td>

                <Td> {prompt.updateDate.substring(0, 10)}</Td>

                <Td>
                  {editablePrompt &&
                  editablePrompt.promptId === prompt.promptId ? (
                    <Flex>
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSavePrompt}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdCancel />}
                        colorScheme="red"
                        ml={2}
                        size="sm"
                        onClick={() => setEditablePrompt(null)}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  ) : (
                    <Flex>
                      <Button
                        colorScheme="blue"
                        width={10}
                        size="sm"
                        onClick={() => handleEditPrompt(prompt)}
                      >
                        <Icon as={MdEdit} boxSize={5} />
                      </Button>

                      <Button
                        colorScheme="red"
                        size="sm"
                        width={10}
                        ml={2}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this Prompt?"
                            )
                          ) {
                            handleDeletePrompt(prompt.promptId);
                          }
                        }}
                      >
                        <Icon as={MdDelete} boxSize={5} />
                      </Button>
                    </Flex>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
};

export default PromptList;
