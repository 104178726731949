// views/NotFound.jsx
import React from 'react';
import { Box, Flex, Text, Button, useColorMode, VStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const history = useHistory();

  const handleGoHome = () => {
    window.location.href = '#/admin/session';
  };

  return (
    <Box px={{ base: "4", md: "6", lg: "8" }} py="5" textAlign="center">
      <Flex
        direction="column"
        align="center"
        justify="center"
        height="100vh"
        gap="4"
      >
        
        <Text fontSize="6xl" fontWeight="bold" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
          4️⃣0️⃣4️⃣
        </Text>
        <Text fontSize="2xl" fontWeight="semibold" color={colorMode === 'light' ? 'gray.600' : 'gray.400'}>
          🤭! The page you’re looking for doesn’t exist(┬┬﹏┬┬)ƪ(˘⌣˘)ʃ.
        </Text>
        <VStack spacing="4">
          <Button onClick={handleGoHome} colorScheme="blue">
            Go to Home
          </Button>
          {/* <Button onClick={toggleColorMode}>
            {colorMode === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode'}
          </Button> */}
        </VStack>
      </Flex>
    </Box>
  );
};

export default NotFound;
