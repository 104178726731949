import { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Input,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Button,
  Select,
  Icon,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone, MdCancel } from "react-icons/md";
import Card from "components/card/Card";

const StyleList = () => {
  const [styles, setStyles] = useState([]);
  const [filteredStyles, setFilteredStyles] = useState([]);
  const [error, setError] = useState("");
  const [editableStyle, setEditableStyle] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [updateUser, setUpdateUser] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    console.log("Access Token:", accessToken);

    if (accessToken) {
      // Split the token into header, payload, and signature
      // eslint-disable-next-line no-unused-vars
      const [header, payload, signature] = accessToken.split(".");
      // Decode the payload (Base64 decoded)
      const decodedPayload = JSON.parse(atob(payload));
      // Extract the username
      const entryUser = decodedPayload.username;
      // Set the entryUser in the state
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []); // Run this effect only once, similar to componentDidMount
  useEffect(() => {
    const fetchStyles = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/style`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch styles");
        }
        const data = await response.json();
        setStyles(data);
        setFilteredStyles(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchStyles();
  }, []);

  // Assuming you have a way to store the original data
  // For example, when you set the editableStyle, also store its original state
  const [originalStyle, setOriginalStyle] = useState(null);

  const handleEditStyle = (style) => {
    setEditableStyle(style);
    setOriginalStyle(style); // Save the original data
  };

  const handleSaveStyle = async () => {
    try {
      const updatedData = {
        styleId: editableStyle.styleId,
        updateUser,
      };

      // Check if name was edited
      if (editableStyle.name !== originalStyle.name) {
        updatedData.name = editableStyle.name;
      }

      // Check if description was edited
      if (editableStyle.description !== originalStyle.description) {
        updatedData.description = editableStyle.description;
      }

      // Check if scientist was edited
      if (editableStyle.scientist !== originalStyle.scientist) {
        updatedData.scientist = editableStyle.scientist;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/style`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json(); // Parse the response JSON
        throw new Error(errorData.error); // Use the custom error message from the server
      }

      setStyles((prev) =>
        prev.map((item) =>
          item.styleId === editableStyle.styleId
            ? { ...item, ...editableStyle }
            : item
        )
      );
      setFilteredStyles((prev) =>
        prev.map((item) =>
          item.styleId === editableStyle.styleId
            ? { ...item, ...editableStyle }
            : item
        )
      );
      setEditableStyle(null);
      setOriginalStyle(null);
    } catch (error) {
      console.error("Error updating style:", error);
      setError(error.message);
    }
  };

  const handleDeleteStyle = async (styleId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/style`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ styleId }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete style");
      }
      setStyles((prev) => prev.filter((item) => item.styleId !== styleId));
      setFilteredStyles((prev) =>
        prev.filter((item) => item.styleId !== styleId)
      );
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = styles.filter(
      (style) =>
        style.name.toLowerCase().includes(query.toLowerCase()) ||
        style.description.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredStyles(filtered);
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        {error && <Text color="red.500">{error}</Text>}
        <Text
          color="gray.800"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Style List
        </Text>
        <Input
          placeholder="Search style..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>
      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr bg="blue.500">
              <Th textColor="white">Index</Th>
              <Th textColor="white">Name</Th>
              <Th textColor="white">Description</Th>
              <Th textColor="white">Scientist</Th>
              <Th textColor="white">Entry User</Th>
              <Th textColor="white">Entry Date</Th>
              <Th textColor="white">Update User</Th>
              <Th textColor="white">Update Date</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredStyles.map((style, index) => (
              <Tr key={style.styleId}>
                <Td>{index + 1}</Td>
                <Td>
                  <Flex>
                    {editableStyle &&
                    editableStyle.styleId === style.styleId ? (
                      <Input
                        width={"100px"}
                        value={editableStyle.name}
                        onChange={(e) =>
                          setEditableStyle({
                            ...editableStyle,
                            name: e.target.value,
                          })
                        }
                      />
                    ) : (
                      style.name
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {editableStyle &&
                    editableStyle.styleId === style.styleId ? (
                      <Input
                        value={editableStyle.description}
                        onChange={(e) =>
                          setEditableStyle({
                            ...editableStyle,
                            description: e.target.value,
                          })
                        }
                      />
                    ) : (
                      style.description
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {editableStyle &&
                    editableStyle.styleId === style.styleId ? (
                      <Input
                        width={"100px"}
                        value={editableStyle.scientist}
                        onChange={(e) =>
                          setEditableStyle({
                            ...editableStyle,
                            scientist: e.target.value,
                          })
                        }
                      />
                    ) : (
                      style.scientist
                    )}
                  </Flex>
                </Td>
                <Td>{style.entryUser}</Td>
                <Td>{style.entryDate.substring(0, 10)}</Td>
                <Td>{style.updateUser}</Td>
                <Td>{style.updateDate.substring(0, 10)}</Td>
                <Td>
                  {editableStyle && editableStyle.styleId === style.styleId ? (
                    <Flex>
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSaveStyle}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdCancel />}
                        colorScheme="red"
                        ml={2}
                        size="sm"
                        onClick={() => setEditableStyle(null)}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  ) : (
                    <Flex>
                      <Button
                        colorScheme="blue"
                        width={10}
                        size="sm"
                        onClick={() => handleEditStyle(style)}
                      >
                        <Icon as={MdEdit} boxSize={5} />
                      </Button>

                      <Button
                        colorScheme="red"
                        size="sm"
                        width={10}
                        ml={2}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this style?"
                            )
                          ) {
                            handleDeleteStyle(style.styleId);
                          }
                        }}
                      >
                        <Icon as={MdDelete} boxSize={5} />
                      </Button>
                    </Flex>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
};

export default StyleList;
