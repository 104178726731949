import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Button, Textarea, useToast } from "@chakra-ui/react";
import { useSession } from "../../../context/SessionContext";

const SessionDetailsPage = ({ history }) => {
  const { sessionData, endSession } = useSession();
  const {
    selectedPatient,
    selectedSchools = [],
    selectedStyles = [],
  } = sessionData || {};
  const [transcriptionText, setTranscriptionText] = useState("");
  const [analysisText, setAnalysisText] = useState("");
  const [isEndingSession, setIsEndingSession] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [schools, setSchools] = useState([]);
  const [styles, setStyles] = useState([]);
  const [patients, setPatients] = useState([]);
  const toast = useToast();
    const [ecgText, setEcgText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [patientsResponse, schoolsResponse, stylesResponse , ecgResponse] =
          await Promise.all([
            fetch(`${process.env.REACT_APP_API_URL}/api/patient`),
            fetch(`${process.env.REACT_APP_API_URL}/api/school`),
            fetch(`${process.env.REACT_APP_API_URL}/api/style`),
            fetch(`${process.env.REACT_APP_API_URL}/api/ecg-data`) 
          ]);

        if (!patientsResponse.ok) throw new Error("Failed to fetch patients");
        if (!schoolsResponse.ok) throw new Error("Failed to fetch schools");
        if (!stylesResponse.ok) throw new Error("Failed to fetch styles");
       if(!ecgResponse.ok) throw new Error("Failed to fetch ecg-data");
  
        const [patientsData, schoolsData, stylesData , ecgData] = await Promise.all([
          patientsResponse.json(),
          schoolsResponse.json(),
          stylesResponse.json(),
                    ecgResponse.json(),

        ]);

        setPatients(patientsData);
        setSchools(schoolsData);
        setStyles(stylesData);
         setEcgText(ecgData || "No available Data");

      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    };

    fetchData();

      const startEcg = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/start-ecg`,
          { method: "POST" }
        );
        if (!response.ok) {
          throw new Error("Failed to start ECG data collection");
        }
        // Initialize or fetch ECG data as needed
        const data = await response.json();
        setEcgText(data.ecgData || "ECG data collection started"); // Adjust based on your response
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    };



    const startTranscription = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/transcribe`,
          { method: "POST" }
        );
        if (!response.ok) {
          throw new Error("Failed to start transcription");
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    };

    startTranscription();
          startEcg();

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [sessionData, history, toast]);

   const handleEcgData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/stoptracking`,
        { method: "POST" }
      );

      if (!response.ok) {
        throw new Error("Failed to stop ECG data collection");
      }

      const data = await response.json();
      setEcgText(data.finalEcgData || "ECG data collection stopped");
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };



  const handleEndSession = async () => {
    setIsEndingSession(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/stop`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ promptContent: sessionData.promptContent }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to stop session");
      }

      const data = await response.json();
      console.log("Data received:", data);
      setTranscriptionText(data.transcription);
      setAnalysisText(data.analysis);

            await handleEcgData();

      setTimeout(() => {
        endSession();
      }, 1000);
    } catch (error) {
      setIsEndingSession(false);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handlePauseResume = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/pause`,
        { method: "POST" }
      );
      if (!response.ok) {
        throw new Error("Failed to pause/resume session");
      }

      const data = await response.json();
      toast({
        title: data.message,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setIsPaused(!isPaused);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const getPatientName = (patientId) => {
    const patient = patients.find((p) => p.patientId === patientId);
    return patient ? `${patient.fname} ${patient.lname}` : "Unknown";
  };

  const getSchoolNames = (schoolIds) => {
    return schoolIds
      .map((schoolId) => {
        const school = schools.find((s) => s.schoolId === schoolId);
        return school ? school.name : "Unknown School";
      })
      .join(", ");
  };

  const getStyleNames = (styleIds) => {
    return styleIds
      .map((styleId) => {
        const style = styles.find((s) => s.styleId === styleId);
        return style ? style.name : "Unknown Style";
      })
      .join(", ");
  };

  return (
    <Box px={{ base: "4", md: "6", lg: "8" }} py="5">
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="2xl" fontWeight="bold">
          Session Details
        </Text>
      </Flex>
      <Box mt="4">
        <Text fontSize="lg" fontWeight="bold">
          Patient:
        </Text>
        <Text>{getPatientName(selectedPatient)}</Text>
        <Text fontSize="lg" fontWeight="bold">
          Schools:
        </Text>
        <Text>{getSchoolNames(selectedSchools)}</Text>
        <Text fontSize="lg" fontWeight="bold">
          Styles:
        </Text>
        <Text>{getStyleNames(selectedStyles)}</Text>
      </Box>
      <Box mt="4">
        <Text fontSize="lg" fontWeight="bold" color="blue.500">
          Transcription
        </Text>
        <Textarea value={transcriptionText} readOnly />
      </Box>
      <Box mt="4">
        <Text fontSize="lg" fontWeight="bold" color="blue.500">
          Analysis
        </Text>
        <Textarea value={analysisText} readOnly />
      </Box>
       <Box mt="4">
        <Text fontSize="lg" fontWeight="bold" color="blue.500">
          ECG Data
        </Text>
        <Textarea value={ecgText} readOnly />
      </Box>
      <Flex mt="4" alignItems="center">
        <Button
          colorScheme="red"
          onClick={handleEndSession}
          disabled={isEndingSession}
        >
          End Session
        </Button>
        <Button
         colorScheme={isPaused ? "yellow" : "blue"} 
         onClick={handlePauseResume} 
         disabled = {isEndingSession} 
         ml={4}
          >
          {isPaused ? "Resume Session" : "Pause Session"}
        </Button>
      </Flex>
    </Box>
  );
};

export default SessionDetailsPage;
