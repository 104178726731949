// import React from 'react';
// import { Box, Flex, Text, Button, useColorMode } from '@chakra-ui/react';
// import { Switch, Route } from 'react-router-dom';
// import DoctorsList from './components/DoctorsList';

// const Doctor = () => {
//   const { colorMode, toggleColorMode } = useColorMode();

//   return (
//     <Box px={{ base: "4", md: "6", lg: "8" }} py="5">
//       <Flex justifyContent="space-between" alignItems="center" mb="4">
//         <Text fontSize="2xl" fontWeight="bold">Doctor</Text>
//         <Button onClick={toggleColorMode}>
//           {colorMode === 'light' ? 'Dark' : 'Light'} Mode
//         </Button>
//       </Flex>
//       <DoctorsList/>
//     </Box>
//   );
// };

// export default Doctor;
import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  useColorMode,
  Container,
  Center,
} from "@chakra-ui/react";
import { MdRemove, MdAdd } from "react-icons/md";
import { Switch, Route } from "react-router-dom";
import { AddDoctor } from "./components/AddDoctor";
import { ListDoctor } from "./components/DoctorList";

const Doctor = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [showRolePermissionForm, setShowRolePermissionForm] = useState(false);

  const toggleRolePermissionForm = () => {
    setShowRolePermissionForm((prevState) => !prevState);
  };

  return (
    <Container maxW="xxl" mt={100}>
      <Center>
        <Button
          colorScheme={showRolePermissionForm ? "red" : "blue"}
          leftIcon={showRolePermissionForm ? <MdRemove /> : <MdAdd />}
          onClick={toggleRolePermissionForm}
          marginBottom="20px"
        >
          {showRolePermissionForm ? "Hide Doctor Form" : "Add Doctor"}
        </Button>
      </Center>

      {showRolePermissionForm && (
        <div style={{ marginBottom: "20px" }}>
          <AddDoctor />
        </div>
      )}

      <ListDoctor />
    </Container>
  );
};

export default Doctor;
