import React, { useState, useEffect } from 'react';
import styles from '../../../assets/css/SignIn.module.css';

const SignIn = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);

  // Function to clear expired local storage items
  const clearExpiredStorage = () => {
    const accessTokenExpiry = localStorage.getItem('accessTokenExpiry');
    const now = new Date().getTime();

    if (accessTokenExpiry && now > parseInt(accessTokenExpiry, 10)) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('accessTokenExpiry');
      localStorage.removeItem('username');
      localStorage.clear();
      window.location.href = '/signin'
      // Redirect to login if needed
      // window.location.href = '/index.jsx';
    }
  };

  // Check and clear expired storage on component load
  useEffect(() => {
    clearExpiredStorage();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'username') setUsername(value);
    if (name === 'password') setPassword(value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
console.log(123)
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        const expiryTime = 28800000; // 8 hours in milliseconds
        const expiryDate = new Date().getTime() + expiryTime;
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('accessTokenExpiry', expiryDate.toString());
        localStorage.setItem('username', username);
        const loginTimestamp = new Date().toISOString();
        localStorage.setItem('loginTimestamp', loginTimestamp);
        console.log('Login Successful by user: ', username);
        window.location.href = '#/admin/session';
      } else if (response.status === 401) {
        console.error("Password incorrect");
        setError("Wrong Password!");
      } else if (response.status === 404) {
        console.error(`User ${username} not found`);
        setError("User Not Found!");
      } else {
        const errorMessage = await response.text();
        setError(errorMessage);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError('An unexpected error occurred.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <section className={styles.body}>
      <div className={styles.section}>
        <form >
          <h1 className={styles.h1}>Login</h1>
          
          <div className={styles.inputBox}>
            <input 
              type="text"
              required
              id="username"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              placeholder="user" // Empty space as placeholder
              className={styles.input}
            />
            <label htmlFor="username" className={styles.inputBoxLabel}>Username</label>
          </div>
          
          <div className={styles.inputBox}>
            <input 
              type={showPassword ? 'text' : 'password'}
              required
              id="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder="password" 
              className={styles.input}
            />
            <label htmlFor="password" className={styles.inputBoxLabel}>Password</label>
          </div>
          
          {error && <div className={styles.errorMessage}>{error}</div>}
          
          <button type="submit" className={styles.button} onClick={handleLogin}>Log In</button>
        </form>
      </div>
    </section>
  );
};

export default SignIn;
