import React, { useState,useEffect } from 'react';
import { Button, Input } from '@chakra-ui/react';
import { motion } from 'framer-motion';

export const InsertPermission = () => {
    // State variables to store form data and status
    const [description, setDescription] = useState('');
    const [entryUser, setEntryUser] = useState(null); 
    const [updateUser, setUpdateUser] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          // Split the token into header, payload, and signature
          // eslint-disable-next-line no-unused-vars
          const [header, payload, signature] = accessToken.split('.');
          // Decode the payload (Base64 decoded)
          const decodedPayload = JSON.parse(atob(payload));
          // Extract the username
          const entryUser = decodedPayload.username;
          // Set the entryUser in the state
          setEntryUser(entryUser);
          setUpdateUser(entryUser);
        } else {
          console.error("Access token not found.");
        }
      }, []); 

    // Function to handle changes in description input
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

   
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            
            
    
           
    
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/permission`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  description,
                  entryUser,
                  updateUser
                }),
              });
    
            // Check if request was successful
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to add permission');
            }
    
            // Reset form fields after successful submission
            setDescription('');
            setError('');
            setSuccess(true);
    
            // Reload the page after a short delay
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } catch (error) {
            // Handle error if POST request fails
            setError(error.message || 'An error occurred. Please try again later.');
        }
    };
    
    return (
        <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
        >
            <h2 style={{ color: '#333', textAlign: 'center' }}>Add New Permission</h2>
            {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
            {success && <p style={{ color: 'green', textAlign: 'center' }}>Permission added successfully!</p>}
            <form onSubmit={handleSubmit}>
                <Input
                    variant="filled"
                    placeholder="Permission Description"
                    value={description}
                    onChange={handleDescriptionChange}
                    required
                />
                <Button colorScheme="blue" type="submit" w="100%">
                    Submit
                </Button>
            </form>
        </motion.div>
    );
};
